import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { HotelBrand, RawHotel } from '../../../types/hotel';

const BRAND_LOGOS_BASE_PATH = '/brand-logos';

const hotelBrandsLogo: Record<HotelBrand | 'unknown', string> = {
	unknown: `${BRAND_LOGOS_BASE_PATH}/unknown.png`,
	//
	// accor: available on http://press.accorhotels.group/fr-logos/
	adagio: `${BRAND_LOGOS_BASE_PATH}/accor__adagio.png`,
	greet: `${BRAND_LOGOS_BASE_PATH}/accor__greet.png`,
	ibisBudget: `${BRAND_LOGOS_BASE_PATH}/accor__ibis-budget.png`,
	ibisStyles: `${BRAND_LOGOS_BASE_PATH}/accor__ibis-styles.png`,
	ibis: `${BRAND_LOGOS_BASE_PATH}/accor__ibis.png`,
	mercure: `${BRAND_LOGOS_BASE_PATH}/accor__mercure.png`,
	mamaShelter: `${BRAND_LOGOS_BASE_PATH}/accor__mama-shelter.png`,
	mGallery: `${BRAND_LOGOS_BASE_PATH}/accor__m-gallery.png`,
	novotel: `${BRAND_LOGOS_BASE_PATH}/accor__novotel.png`,
	novotelSuite: `${BRAND_LOGOS_BASE_PATH}/accor__novotel.png`,
	pullman: `${BRAND_LOGOS_BASE_PATH}/accor__pullman.png`,
	//
	// louvre hotels: available on https://www.louvrehotels.com/en/our-brands
	louvreCampanile: `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__campanile.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__golden-tulip.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__hotels-and-preference.png`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__kyriad-direct.jpg`,
	louvreKyriad: `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__kyriad.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__metropolo.jpg`,
	louvrePremiereClasse: `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__premiere-classe.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__royal-tulip.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__sarovar.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/louvre-hotels__tulip-inn.jpg`,
	//
	// marriott: available on https://news.marriott.com/brands
	// `${BRAND_LOGOS_BASE_PATH}/marriott__ac.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__aloft.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__autograph-collection.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__bonvoy.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__bulgari.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__courtyard.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__delta.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__design.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__edition.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__element-by-westin.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__executive-appartments.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__fairfield.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__four-points.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__gaylord.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__jw.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__luxury-collection.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__marriott.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__meridien.png`,
	marriottMoxy: `${BRAND_LOGOS_BASE_PATH}/marriott__moxy.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__protea.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__renaissance.png`,
	marriottResidenceInn: `${BRAND_LOGOS_BASE_PATH}/marriott__residence-inn.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__ritz-carlton.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__sheraton.jpg`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__springhill-suites.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__st-regis.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__towneplace-suites.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__tribute-portfolio.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__vacation-club.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__w.png`,
	// `${BRAND_LOGOS_BASE_PATH}/marriott__westin.png`,
	//
	// others / independant
	esatitude: `${BRAND_LOGOS_BASE_PATH}/adapeiam__esatitude.png`,
	rossignolStrato: `${BRAND_LOGOS_BASE_PATH}/rossignol__strato.png`,
	hotelCenter: `${BRAND_LOGOS_BASE_PATH}/hotelCenter.png`,
	moka: `${BRAND_LOGOS_BASE_PATH}/moka.jpg`,
};

const ContainerPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	paddingLeft: theme.spacing(10),
	marginTop: theme.spacing(3),
	marginBottom: theme.spacing(1),
	marginLeft: theme.spacing(2),
	marginRight: theme.spacing(1),
	cursor: 'pointer',
	position: 'relative',
	'&:hover': {
		background: theme.palette.primary.light,
	},
}));

const LogoContainerDiv = styled('div')(({ theme }) => ({
	background: '#f1f1f1',
	width: '15%',
	paddingBottom: '15%',
	position: 'absolute',
	top: theme.spacing(-2),
	left: theme.spacing(-2),
	borderRadius: theme.shape.borderRadius,
}));

const LogoHolderDiv = styled('div')({
	display: 'flex',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	alignItems: 'center',
});

const LogoImg = styled('img')({
	maxWidth: '100%',
});

type HotelChooserHandleProps = {
	hotel: RawHotel;
	currentWorkHotel: RawHotel | null;
	handleHotelSelection: any;
};

export const HotelChooserHandle: React.FC<HotelChooserHandleProps> = ({
	hotel,
	currentWorkHotel,
	handleHotelSelection,
}) => (
	<ContainerPaper
		onClick={handleHotelSelection(hotel)}
		sx={
			currentWorkHotel && hotel.id === currentWorkHotel.id
				? {
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
					}
				: {}
		}
	>
		<LogoContainerDiv>
			<LogoHolderDiv>
				{hotelBrandsLogo[hotel.brand] ? (
					<LogoImg src={hotelBrandsLogo[hotel.brand]} alt={hotel.brand} />
				) : (
					<LogoImg src={hotelBrandsLogo.unknown} alt={hotel.brand} />
				)}
			</LogoHolderDiv>
		</LogoContainerDiv>
		<Typography variant="caption" component="div" sx={{ textAlign: 'right' }}>
			{hotel.id} / {hotel.city}
		</Typography>
		<Typography variant="h6" component="h2">
			{hotel.name}
		</Typography>
	</ContainerPaper>
);
